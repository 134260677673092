<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">学员统计</a>
        </span>
      </div>
      <div class="framePage-body">
        <div
            class="operationControl flexdc OpStudentstatistics"
            style="align-items: flex-start"
        >
          <div class="searchbox">
            <div>
              <div
                  title="学员姓名"
                  class="ci-full"
                  style="display: flex; align-items: center"
              >
                <span
                    class="itemLabel"
                    style="
                    min-width: 5rem;
                    text-align: right;
                    font-size: 14px;
                    padding: 0 0.5rem;
                  "
                >学员姓名:</span
                >
                <el-input
                    v-model="userName"
                    type="text"
                    size="small"
                    placeholder="请输入学员姓名"
                    clearable
                />
              </div>
              <div
                  title="身份证号"
                  class="ci-full"
                  style="display: flex; align-items: center"
              >
                <span
                    class="itemLabel"
                    style="
                    min-width: 7rem;
                    text-align: right;
                    font-size: 14px;
                    padding: 0 8px 0 0;
                  "
                >身份证号:</span
                >
                <el-input
                    v-model="ruleForm.idCard"
                    type="text"
                    size="small"
                    placeholder="请输入身份证号"
                    clearable
                />
              </div>
              <div
                  title="联系电话"
                  class="ci-full"
                  style="display: flex; align-items: center"
              >
                <span
                    class="itemLabel"
                    style="
                    min-width: 5rem;
                    text-align: right;
                    font-size: 14px;
                    padding: 0 0.5rem;
                  "
                >联系电话:</span
                >
                <el-input
                    v-model="mobile"
                    type="text"
                    size="small"
                    placeholder="请输入联系电话"
                    clearable
                />
              </div>
              <div
                  title="行政区划"
                  class="ci-full"
                  style="display: flex; align-items: center"
              >
                <span
                    class="itemLabel"
                    style="
                    min-width: 5rem;
                    text-align: right;
                    font-size: 14px;
                    padding: 0 0.5rem;
                  "
                >行政区划:</span
                >
                <el-cascader
                    clearable
                    filterable
                    v-model="ruleForm.areaId"
                    :options="areaTreeList"
                    :props="propsArea"
                    size="small"
                ></el-cascader>
              </div>
              <transition name="el-zoom-in-center">
                <el-button
                    class="bgc-bv"
                    style="margin-left: 10px"
                    round
                    @click="getData()"
                    size="small"
                    v-show="dialogVisible == false"
                >查询</el-button
                >
              </transition>
            </div>
          </div>
          <el-collapse-transition>
            <div
                class="searchbox"
                style="margin-top: 10px"
                
            >
              <el-form label-width="80px" ref="ruleForm" :model="ruleForm">
                <el-form-item
                    label="课程名称:"
                    prop="courseName"
                    class="ci-full"
                >
                  <el-input
                      v-model="ruleForm.courseName"
                      type="text"
                      size="small"
                      placeholder="请输入课程名称"
                      clearable
                  />
                </el-form-item>
                <el-form-item label="认证状态:" label-width="7rem" prop="evaluate" class="ci-full">
                  <el-select
                      v-model="ruleForm.authentication"
                      placeholder="请选择"
                      clearable
                      size="small"
                  >
                    <el-option
                        v-for="item in authenticationList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>

                <el-form-item
                    label="学习状态:"
                    prop="completeState"
                    class="ci-full el-selectBox"
                >
                  <el-select
                      v-model="ruleForm.completeState"
                      placeholder="请选择"
                      clearable
                      size="small"
                  >
                    <el-option
                        v-for="item in complete"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                    label="通过考试:"
                    prop="paperIspass"
                    class="ci-full"
                >
                  <el-select
                      v-model="ruleForm.paperIspass"
                      placeholder="请选择"
                      clearable
                      size="small"
                  >
                    <el-option
                        v-for="item in passExamList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>

              </el-form>
            </div>
          </el-collapse-transition>
          <el-collapse-transition>
            <div
                class="searchbox"
                style="margin-top: 10px"
                
            >
              <el-form label-width="80px" ref="ruleForm" :model="ruleForm">
                <el-form-item
                    label="是否结业:"
                    prop="graduationState"
                    class="ci-full"
                >
                  <el-select
                      v-model="ruleForm.graduationState"
                      placeholder="请选择"
                      clearable
                      size="small"
                      style="width: 100%"
                  >
                    <el-option
                        v-for="(item, index) in graduationStateList"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form label-width="80px" ref="ruleForm" :model="ruleForm">
                  <el-form-item
                      label="结业时间:"
                      prop="graduationTime"
                      label-width="7rem"
                      class="ci-full"
                  >
                    <el-date-picker
                        v-model="ruleForm.graduationTime"
                        size="small"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                  </el-form-item>
                  <el-form-item style="margin-left: 10px" class="btns">
                    <el-button
                        class="bgc-bv"
                        round
                        @click="getData()"
                        size="small"
                    >查询</el-button
                    >
                    <el-button
                        type="primary"
                        class="bgc-bv"
                        round
                        @click="getExport()"
                    >导出</el-button
                    >
                    <el-button class="bgc-bv" round @click="toChange" size="small"
                    >自定义表头</el-button
                    >
                    <el-button @click="cancelForm('ruleForm')" type="text"
                    >清空搜索条件</el-button
                    >
                  </el-form-item>
                </el-form>

              </el-form>
            </div>
          </el-collapse-transition>

        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
                ref="multipleTable"
                :data="tableData"
                :height="tableHeight"
                size="small"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="tableHeader"
                stripe
                v-if="showtable"
            >
              <el-table-column
                  label="序号"
                  align="center"
                  type="index"
                  :index="indexMethod"
                  fixed
              />
              <el-table-column
                  label="学员姓名"
                  align="left"
                  show-overflow-tooltip
                  prop="userName"
                  fixed
              />
              <el-table-column
                  label="身份证号"
                  align="left"
                  show-overflow-tooltip
                  prop="idcard"
                  min-width="155"
                  fixed
              />
              <el-table-column
                  v-for="(el, index) in tableHead"
                  :key="index"
                  :label="el.label"
                  :align="el.textAlign"
                  :min-width="el.width"
              >
                <template slot-scope="scope">
                  <el-tooltip
                      v-if="el.tooltip && scope.row.completeState == '40'"
                      class="item"
                      effect="dark"
                      :content="scope.row.notCompleteReasons"
                      placement="top"
                  >
                    <span
                        style="
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                      "
                    >{{ getValue(el, scope.row) }}</span
                    >
                  </el-tooltip>
                  <span v-else>
                    {{ getValue(el, scope.row) }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                  label="操作"
                  fixed="right"
                  align="center"
                  width="200px"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                      type="text"
                      style="padding: 0px 15px"
                      size="mini"
                      @click="
                      handledetail(scope.row.userId, scope.row.projectCourseId)
                    "
                  >学习详情</el-button
                  >
                  <el-button
                      type="text"
                      style="padding: 0px 15px"
                      size="mini"
                      @click="handleTrajectory(scope.row)"
                  >学习轨迹</el-button
                  >
                </div>
              </el-table-column>
              <!-- <template slot-scope="scope">
                  <span>{{ parseInt(scope.row.lastTime) | moment}}</span>
                </template>
              </el-table-column>-->
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
            :apiData="apiData"
            @sizeChange="sizeChange"
            @getData="getData"
        />
      </div>
    </div>

    <el-dialog
        title="自定义表头"
        :visible.sync="tableHeadDialog"
        width="30%"
        class="custom"
        @close="cencal"
    >
      <p style="padding-left: 20px; margin-bottom: 10px">
        勾选需要显示在列表上的项，可拖动调整顺序
      </p>
      <div>
        <el-tree
            ref="headTree"
            :data="treeDataCopy"
            default-expand-all
            draggable
            :allow-drop="allowDrop"
            :allow-drag="allowDrag"
        >
          <template slot-scope="{ node, data }">
            <el-checkbox
                @change="
                (val) => {
                  handleCheckChange(val, data);
                }
              "
                v-model="data.checked"
            >{{ node.label }}</el-checkbox
            >
          </template>
        </el-tree>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cencal">取 消</el-button>
        <el-button class="bgc-bv" @click="resetHeadOk">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import { Message } from "element-ui";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";

export default {
  name: "set/StudentStatistics",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      showtable: false,
      dialogVisible: true, //弹框
      classId: "",
      // 导出
      projectCode: "",
      classListModel: [],
      userName: "",
      mobile: "",
      graduationState: "",
      completeState: "",
      complete: [],
      evaluateList: [
        {
          value: "",
          label: "全部",
        },
        {
          value: true,
          label: "是",
        },
        {
          value: false,
          label: "否",
        },
      ],
      graduationStateList: [
        {
          value: "",
          label: "全部",
        },
        {
          value: true,
          label: "是",
        },
        {
          value: false,
          label: "否",
        },
      ],
      passExamList: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "10",
          label: "是",
        },
        {
          value: "20",
          label: "否",
        },
        {
          value: "30",
          label: "无配置",
        },
      ],
      ruleForm: {
        idCard: "",
        projectName: "",
        projectCode: "",
        courseName: "",
        completeState: '',
        evaluate: "",
        paperIspass: "",
        userCompId: "",
        unitCompId: "",
        createTime: "",
        userWorkUnit: "",
        hasIdPhoto: "",
        graduationTime: "",
        areaId: "",
        authentication: "",
      },
      hasIdPhotoSelect: [
        {
          value: true,
          label: "有",
        },
        {
          value: false,
          label: "无",
        },
      ],
      // 自定义表头
      tableHeadDialog: false, //
      fixedHeader: [
        {
          label: "学员姓名", //表头名称
          propName: "userName", //后台对应字段名称
          textAlign: "center", //文字对齐方式
          renderType: "selfJudgment", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应keys
          width: "60", // 列的宽
          order: -1,
          id: "1",
          checked: true,
        },
        {
          label: "身份证号", //表头名称
          propName: "idcard", //后台对应字段名称
          textAlign: "left", //文字对齐方式
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "100", // 列的宽
          order: 0,
          id: "2",
          checked: true,
        }
      ],
      treeData: [
        {
          label: "联系电话", //表头名称
          propName: "mobile", //后台对应字段名称
          textAlign: "left", //文字对齐方式
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "100", // 列的宽
          order: 1,
          id: "1",
          checked: true,
        },
        {
          label: "认证状态", //表头名称
          propName: "authentication", //后台对应字段名称
          textAlign: "right", //文字对齐方式
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "100", // 列的宽
          order: 2,
          id: "2",
          checked: true,
        },
        {
          label: "课程名称", //表头名称
          propName: "courseName", //后台对应字段名称
          textAlign: "left", //文字对齐方式
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "200", // 列的宽
          order: 3,
          id: "3",
          checked: true,
        },
        {
          label: '要求总学时', //表头名称
          propName: "requireLessonNum", //后台对应字段名称
          textAlign: "right", //文字对齐方式
          renderType: "nummer", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 4,
          id: "4",
          checked: true,
        },
        {
          label: '已完成学时', //表头名
          propName: "studyLessonNum", //后台对应字段名称
          textAlign: "right", //文字对齐方式
          renderType: "nummer", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 5,
          id: "5",
          checked: true,
        },
        {
          label: "是否考试通过", //表头名称
          propName: "paperState", //后台对应字段名称
          textAlign: "center", //文字对齐方式
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "100", // 列的宽
          order: 6,
          id: "6",
          checked: true,
        },

        {
          label: "考试次数", //表头名称
          propName: "paperNum", //后台对应字段名称
          textAlign: "right", //文字对齐方式
          renderType: "nummer", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "100", // 列的宽
          order: 7,
          id: "7",
          checked: true,
        },
        {
          label: "考试成绩", //表头名称
          propName: "paperScore", //后台对应字段名称
          textAlign: "right", //文字对齐方式
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "100", // 列的宽
          order: 8,
          id: "8",
          checked: true,
        },
        {
          label: "学时学习状态", //表头名称
          propName: "completeState", //后台对应字段名称
          textAlign: "center", //文字对齐方式
          renderType: "status", //渲染类型
          timeType: "", //时间字段的渲染类型
          tooltip: true, //特殊展示 - 悬浮展示提示
          dictionaries: "LEARNINGSTATE", //字典对应key
          width: "100", // 列的宽
          order: 9,
          id: "9",
          checked: true,
        },
        {
          label: "有效学习时长", //表头名称
          propName: "totalTime", //后台对应字段名称
          textAlign: "right", //文字对齐方式
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "120", // 列的宽
          order: 10,
          id: "10",
          checked: true,
        },
        {
          label: "最后一次学习时间", //表头名称
          propName: "lastTime", //后台对应字段名称
          textAlign: "left", //文字对齐方式
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "200", // 列的宽
          order: 11,
          id: "11",
          checked: true,
        },
        {
          label: "是否结业", //表头名称
          propName: "graduationState", //后台对应字段名称
          textAlign: "left", //文字对齐方式
          renderType: "selfJudgment", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "100", // 列的宽
          order: 12,
          id: "12",
          checked: true,
        },
        {
          label: "结业时间", //表头名称
          propName: "graduationTime", //后台对应字段名称
          textAlign: "left", //文字对齐方式
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "200", // 列的宽
          order: 13,
          id: "13",
          checked: true,
        },
        {
          label: "学习进度(%)", //表头名称
          propName: "percentage", //后台对应字段名称
          textAlign: "left", //文字对齐方式
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "100", // 列的宽
          order: 14,
          id: "14",
          checked: true,
        },
        {
          label: "所属部门", //表头名称
          propName: "department", //后台对应字段名称
          textAlign: "right", //文字对齐方式
          renderType: "", //渲染类型
          timeType: "", //时间字段的渲染类型
          dictionaries: "", //字典对应key
          width: "150", // 列的宽
          order: 15,
          id: "15",
          checked: true,
        },
      ],
      tableHead: [],
      CompanyList: [],
      unitCompanyList: [],
      isCC: false, // 是否长春
      areaTreeList: [], //行政区划
      propsArea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      authenticationList: [], //认证状态
    };
  },
  created() {
    this.getTableHeight();
    this.getCompleteList();
    this.getAreaTree();
    this.getCompAreaId();
  },
  watch: {
    classId: function (val) {
      if (val == "") {
        this.classListModel = [];
      }
    },
  },
  computed: {
    ...mapGetters({
      userJson: "getUser",
      downloadItems: "getDownloadItems",
    }),
  },
  methods: {
    // 根据字段转换页面展示的内容
    getValue(el, row) {
      // 状态
      if (el.renderType == "status") {
        return this.$setDictionary(el.dictionaries, row[el.propName]);
      } else if (el.renderType == "time") {
        // 时间
        if (el.timeType == "moment") {
          return row[el.propName]
              ? this.$moment(row[el.propName]).format("YYYY-MM-DD HH:mm:ss")
              : "--";
        } else if (el.timeType == "custom") {
          return this.getTime(row[el.propName]);
        } else {
          return row[el.propName];
        }
      } else if (el.renderType == "selfJudgment") {
        //没有字典根据返回自己判断是否
        // 是否评价
        if (el.label == "是否评价") {
          if (row[el.propName] == true) {
            return "是";
          } else {
            return "否";
          }
        } else if (el.label == "是否结业") {
          if (row[el.propName] == true) {
            return "是";
          } else {
            return "否";
          }
        } else if (el.label == "性别") {
          if (row[el.propName] == "1") {
            return "男";
          } else if (row[el.propName] == "2") {
            return "女";
          } else {
            return "";
          }
        }
      } else if (el.renderType == "nummer") {
        return row[el.propName] || "0";
      } else {
        // 其他
        return row[el.propName] || "--";
      }
    },
    // 自定义确定
    resetHeadOk() {
      let list = [];
      let saveData = [];
      this.treeDataCopy.forEach((el, index) => {
        saveData.push({ id: el.id, order: index, checked: el.checked });
        if (el.checked) {
          list.push(el);
        }
      });
      this.tableHead = list;
      this.tableHeadDialog = false;
      this.$post("/sys/admin/savePageConfig", {
        configInfo: JSON.stringify(saveData),
        pageName: "StudentstatisticsLess",
      }).then((res) => {
        this.getTableHead();
      });
    },
    //点击自定义表头
    toChange() {
      this.tableHeadDialog = true;
      this.handle();
    },
    cencal() {
      this.tableHeadDialog = false;
    },
    getTableHead() {
      this.$post("/sys/admin/findAdminConfig", {
        pageName: "StudentstatisticsLess",
      })
          .then((res) => {
            if (res.status == 0) {
              const data = res.data || {};
              let list = [];
              if (data.configInfo && data.configInfo != "") {
                list = JSON.parse(data.configInfo);
              }
              if (list.length) {
                list.map((el) => {
                  this.treeData.forEach((it, index) => {
                    if (el.id == it.id) {
                      it.checked = el.checked;
                      it.order = el.order;
                    }
                  });
                });
                this.treeData.sort((a, b) => {
                  return a.order - b.order;
                });
                this.handle();
              } else {
                this.handle();
              }
            } else {
              this.tableHead = [...this.treeData];
            }
          })
          .catch((err) => {
            this.tableHead = [...this.treeData];
          });
    },
    handle() {
      this.tableHead = [];
      this.treeDataCopy = [];
      if (this.isCC) {
        this.treeData = this.treeData.filter((el) => el.label != "考试次数");
        this.treeData = this.treeData.filter(
            (el) => el.label != "有效学习时长"
        );
      }
      this.treeData.forEach((el) => {
        this.treeDataCopy.push(Object.assign({}, el));
      });
      // 展示勾选的
      this.treeDataCopy.forEach((el) => {
        if (el.checked) {
          this.tableHead.push(el);
        }
      });
      this.showtable = true;
    },
    // 判断拖拽的位置
    allowDrop(draggingNode, dropNode, type) {
      return type !== "inner";
    },
    // 节点是否可以拖拽
    allowDrag(draggingNode) {
      // if (draggingNode.label == "课程名称") {
      //   return false;
      // }
      return true;
    },
    handleCheckChange(val, data) {},
    changeLoadType() {
      this.classId = "";
      this.projectCode = "";
    },
    getExport() {
      const params = {
        commentState: this.ruleForm.evaluate,
        paperIsComplete: this.ruleForm.paperIspass,
        graduationState: this.ruleForm.graduationState,
        hasIdPhoto: this.ruleForm.hasIdPhoto,
      };
      if (this.ruleForm.createTime) {
        params.createTimeBeginDate = this.ruleForm.createTime[0];
        params.createTimeEndDate = this.ruleForm.createTime[1];
      }
      if (this.ruleForm.graduationTime) {
        params.graduationTimeStart = this.ruleForm.graduationTime[0];
        params.graduationTimeEnd = this.ruleForm.graduationTime[1];
      }
      if (this.ruleForm.completeState) {
        params.complateState = this.ruleForm.completeState;
      }
      if (this.userName) {
        params.userName = this.userName;
      }
      if (this.ruleForm.idCard) {
        params.idcard = this.ruleForm.idCard;
      }
      if (this.ruleForm.projectName) {
        params.projectName = this.ruleForm.projectName;
      }
      if (this.ruleForm.projectCode) {
        params.projectCode = this.ruleForm.projectCode;
      }
      if (this.ruleForm.courseName) {
        params.courseName = this.ruleForm.courseName;
      }
      if (this.ruleForm.unitCompId) {
        params.compNameId = this.ruleForm.unitCompId;
      }
      if (this.ruleForm.userWorkUnit) {
        params.userWorkUnit = this.ruleForm.userWorkUnit;
      }
      if (this.mobile) {
        params.mobile = this.mobile;
      }
      if (this.ruleForm.userCompId) {
        params.userCompId = this.ruleForm.userCompId;
      }
      if (this.ruleForm.areaId) {
        params.areaId = this.ruleForm.areaId;
      }
      if (this.ruleForm.authentication) {
        params.authentication = this.ruleForm.authentication;
      }
      params.treeData = this.fixedHeader.concat(this.treeData);
      this.$post("/biz/report/user/exportSelf", params)
          .then((res) => {
            if (res.status == "0") {
              let list = res.data;
              if (!this.downloadItems.includes(list.taskId)) {
                this.$store.dispatch("pushDownloadItems", list.taskId);
              } else {
                this.$message.warning(
                    "[" + list.fileName + "]已经申请下载,请耐心等待"
                );
              }
            } else {
              this.$message.error(res.message);
            }
          })
          .catch((err) => {
            return;
          });
    },
    // 班级名称搜索列表
    getClassListModel(query) {
      if (query.trim()) {
        this.$post("/biz/project/queryProjectNameList", { projectName: query })
            .then((res) => {
              if (res.status == 0) {
                this.classListModel = res.data || [];
              }
            })
            .catch(() => {
              return;
            });
      } else {
        this.classListModel = [];
      }
    },
    getCompleteList() {
      const studyList = this.$setDictionary("LEARNINGSTATE", "list");
      const authenticationList = this.$setDictionary("AUTHENTICATION", "list");
      for (const key in studyList) {
        if (key != "" && key != "10") {
          this.complete.push({
            value: key,
            label: studyList[key],
          });
        }
      }
      for (const key in authenticationList) {
        this.authenticationList.push({
          value: key,
          label: authenticationList[key],
        });
      }
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        commentState: this.ruleForm.evaluate,
        paperIsComplete: this.ruleForm.paperIspass,
        graduationState: this.ruleForm.graduationState,
        hasIdPhoto: this.ruleForm.hasIdPhoto,
      };
      if (this.ruleForm.createTime) {
        params.createTimeBeginDate = this.ruleForm.createTime[0];
        params.createTimeEndDate = this.ruleForm.createTime[1];
      }
      if (this.ruleForm.graduationTime) {
        params.graduationTimeStart = this.ruleForm.graduationTime[0];
        params.graduationTimeEnd = this.ruleForm.graduationTime[1];
      }
      if (this.ruleForm.completeState) {
        params.complateState = this.ruleForm.completeState;
      }
      if (this.userName) {
        params.userName = this.userName;
      }
      if (this.ruleForm.idCard) {
        params.idcard = this.ruleForm.idCard;
      }
      if (this.ruleForm.projectName) {
        params.projectName = this.ruleForm.projectName;
      }
      if (this.ruleForm.projectCode) {
        params.projectCode = this.ruleForm.projectCode;
      }
      if (this.ruleForm.courseName) {
        params.courseName = this.ruleForm.courseName;
      }
      if (this.ruleForm.unitCompId) {
        params.compNameId = this.ruleForm.unitCompId;
      }
      if (this.ruleForm.userWorkUnit) {
        params.userWorkUnit = this.ruleForm.userWorkUnit;
      }
      if (this.mobile) {
        params.mobile = this.mobile;
      }
      if (this.ruleForm.userCompId) {
        params.userCompId = this.ruleForm.userCompId;
      }
      if (this.ruleForm.areaId) {
        params.areaId = this.ruleForm.areaId;
      }
      if (this.ruleForm.authentication) {
        params.authentication = this.ruleForm.authentication;
      }
      this.doFetch({
        url: "/biz/report/user/page-list-self",
        params,
        pageNum,
      });
    },
    // 查询是否长春 在给table赋值，长春的去掉考试次数
    handleListDataBack(data) {
      this.$post("/sys/company/containCCProject", {}).then((res) => {
        if (res.status == "0") {
          this.isCC = res.data;
          this.tableData = data;
          this.getTableHead();
        }
      });
    },
    getTime(val) {
      if (!val) {
        return "";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return h + "时" + m + "分" + s + "秒" || "";
    },
    getsearch() {
      this.getData();
      this.dialogVisible = false;
      // this.doClose()
    },
    // 高级搜索弹窗
    search() {
      this.dialogVisible = !this.dialogVisible;
      this.getTableHeight();
    },
    //取消
    cancelForm(formName) {
      this.ruleForm = {
        idCard: "",
        projectName: "",
        projectCode: "",
        courseName: "",
        completeState: "",
        evaluate: "",
        paperIspass: "",
        userCompId: "",
        graduationState: "",
        createTime: "",
        areaId: "",
        authentication: "",
      };
      this.userName = "";
      this.mobile = "";
    },
    handledetail(userId, projectCourseId) {
      this.$router.push({
        path: "/web/operate/learningDetails",
        // 上海传type=10
        query: { userId, projectCourseId,type:'10' },
      });
    },
    handleTrajectory(row) {
      let rowJson = {
        userId: row.userId,
        projectCourseId: row.projectCourseId,
        projectName: row.projectName,
        courseName: row.courseName,
        userName: row.userName,
        idcard: row.idcard,
        mobile: row.mobile,
      };
      let data = JSON.stringify(rowJson);
      this.$router.push({
        path: "/web/set/StudentstatisticsNoSubordinateTrackMergingList",
        query: {
          data,
          userId: row.userId,
          projectCourseId: row.projectCourseId,
        },
      });
    },
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
            .then((res) => {
              if (res.status == 0) {
                this.CompanyList = res.data || [];
              }
            })
            .catch(() => {
              return;
            });
      } else {
        this.CompanyList = [];
      }
    },
    /* 单位名称 */
    getUnitCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyFJList", { compName: query })
            .then((res) => {
              if (res.status == 0) {
                this.unitCompanyList = res.data || [];
              }
            })
            .catch(() => {
              return;
            });
      } else {
        this.unitCompanyList = [];
      }
    },
    //获取行政区划
    getAreaTree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areaTreeList = ret.data;
      });
    },
    // 获取当前机构账号的区划id - 上海用
    getCompAreaId(){
      this.$post("/sys/company/id",{compId: this.userJson.compId}).then((res) => {
        if(res.data?.areaId && this.zF.getString2(res.data?.areaId,3) == '310'){
          this.treeData.forEach(element => {
            if(element.id == '4') {
              element.label = '课件总数';
              element.propName = 'kpointTotalNum';
            }
            if(element.id == '5') {
              element.label = '已完成课件数';
              element.propName = 'completeKpointNum';
            }
          });
        }
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
          window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 235 - 3 * 47 + 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less">
.model {
  .conTip {
    text-align: justify;
    margin-bottom: 20px;
  }
  .itemLabel {
    margin: 10px 0;
  }
}
.OpStudentstatistics {
  > div {
    width: 100%;
    justify-content: space-between;
  }
  .el-form {
    display: flex;
    .el-form-item__label {
      padding: 0 8px 0 0 !important;
    }
    .el-form-item {
      margin-bottom: 0;
    }
    .btns {
      .el-form-item__content {
        margin-left: 0 !important;
      }
    }
  }
  .completeStates {
    .el-form-item__label {
      min-width: 7rem;
    }
    .el-form-item__content {
      margin-left: 7rem !important;
    }
  }
}

.custom {
  .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #5c6be8;
    border-color: #5c6be8;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #5c6be8;
  }
}
.el-select__tags-text {
  min-width: 2rem !important;
}
.el-selectBox {
  .el-select {
    // min-width: 15rem;
    .el-tag {
      min-width: 10px !important;
    }
  }
}
</style>
